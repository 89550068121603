import React, { Component } from "react";
import ChangeMail from "../../components/Form/ChangeMail/ChangeMail";
import ChangePswd from "../../components/Form/ChangePswd/ChangePswd";
import AddInvite from "../../components/Form/AddInvite/AddInvite";
import "./Profil.css";
import DeleteInvite from "../../components/Button/DeleteInvite/DeleteInvite";
import { Table, Card, CardBody } from "reactstrap";
import ChangeInfos from "../../components/Form/ChangeInfos/ChangeInfos";
class Profil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      invite: [],
      update: 0,
      refresh: null,
    };
  }

  componentDidMount() {
    this.getUser();
    this.getInvite();
    let refresh_interval = setInterval(this.refresh, 300000);
    this.setState({ refresh: refresh_interval });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      console.log("update");
      this.getUser();
      this.getInvite();
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }
  refresh = () => {
    this.getInvite();
    this.getUser();
  };
  getUser = () => {
    fetch(
      "https://asicar.herokuapp.com/auth/infos/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          user: resData.user,
        });
      })
      .catch((err) => console.log(err));
  };
  deleteinvite = (id) => {
    fetch("https://asicar.herokuapp.com/auth/deleteuser/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        this.setState({ update: !this.state.update });
        return res.json();
      })
      .catch((err) => console.log(err));
  };
  changemail = (e, data) => {
    fetch(
      "https://asicar.herokuapp.com/auth/changemail/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data[0],
        }),
      }
    )
      .then((resData) => {
        this.setState({ update: !this.state.update });
        alert("Email modifié !");
        console.log(resData);
      })
      .catch((err) => console.log(err));
  };
  changepwd = (e, data) => {
    fetch(
      "https://asicar.herokuapp.com/auth/changepwd/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pwd: data[0],
          new_pwd: data[1],
        }),
      }
    )
      .then((resData) => {
        this.setState({ update: !this.state.update });
        alert("Mot de passe modifié !");
        console.log(resData);
      })
      .catch((err) => console.log(err));
  };

  getInvite = () => {
    fetch(
      "https://asicar.herokuapp.com/auth/getinvite/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          invite: resData.user,
        });
      })
      .catch((err) => console.log(err));
  };

  changerole = (e, data) => {
    e.preventDefault();
    fetch("https://asicar.herokuapp.com/auth/changerole/" + data[0], {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: data[1],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        this.setState({ update: !this.state.update });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  addinvite = (e, data) => {
    e.preventDefault();
    fetch("https://asicar.herokuapp.com/auth/addinvite", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data[0],
        password: data[1],
        firstname: "Nouveau",
        lastname: "Invité",
        role: "Guest",
        admin_id: localStorage.getItem("userId"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  UpdateInfos = (e, data) => {
    fetch(
      "https://asicar.herokuapp.com/auth/upuser/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: data[0],
          lastname: data[1],
          societe: data[2],
          phone: data[3],
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update });
        alert("Vos informations ont bien été modifié !");
        //this.setState({ user: resData.user });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let invite = null;
    let addinvite = null;
    let user_infos = null;
    if (this.state.user.role === "Utilisateurs") {
      user_infos = <p>Votre compte est en attente de vérification</p>;
    }
    if (
      this.state.user.role !== "Guest" &&
      this.state.user.role !== "Utilisateurs"
    ) {
      addinvite = <AddInvite onAdd={this.addinvite} />;
      invite = (
        <Card style={{ width: "80rem" }} className=" ml-3 mr-3">
          <CardBody>
            <h2>Liste des invités</h2>
            <Table className="table">
              <thead>
                <tr>
                  <th className="text-center">Prénom</th>
                  <th className="text-center">Nom</th>
                  <th className="text-center">Email</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.invite.map((invite) => (
                  <tr key={invite._id}>
                    <td className="text-center">{invite.firstname}</td>
                    <td className="text-center">{invite.lastname}</td>
                    <td className="text-center">{invite.email}</td>
                    <td className="text-right">
                      <DeleteInvite
                        id={invite._id}
                        onDelete={this.deleteinvite}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      );
    }
    return (
      <div className="ml-2 mt-3">
        <h1 className="text-center">
          Bonjour {this.state.user.firstname} {this.state.user.lastname} (
          {this.state.user.role})
        </h1>
        <div className="profil">
          <ChangeMail onAdd={this.changemail} />
          <ChangePswd onAdd={this.changepwd} />

          <ChangeInfos
            firstname={this.state.user.firstname}
            lastname={this.state.user.lastname}
            societe={this.state.user.societe}
            phone={this.state.user.phone}
            onUpdate={this.UpdateInfos}
          />
          {addinvite}
        </div>
        <div className="profil">{invite}</div>
        <div>{user_infos}</div>
      </div>
    );
  }
}

export default Profil;
