import React from "react";
import "./VA.css";

import { Badge, Button, Card, CardBody, CardFooter } from "reactstrap";

const VehiculeGuest = (props) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <Card style={{ width: "20rem" }} className="mr-2 mt-2">
      <div className="mt-1 ml-1">
        <Badge color={props.en_cours ? "success" : "danger"}>En cours</Badge>
        <Badge color={props.pl ? "success" : "danger"}>Livraison</Badge>
        <Badge color="primary">Ajouté par Vous</Badge>
      </div>
      <CardBody>
        <h2 className="title">
          {props.modele} - {props.immatriculation}
        </h2>
        <p>
          {props.societe} - {(props.phone) ? "0" + props.phone.toString(): "Pas de numéro"}
        </p>
        <p>{new Date(props.arrival).toLocaleDateString("fr-FR", options)}</p>
        <p>{new Date(props.deliver).toLocaleDateString("fr-FR", options)}</p>
        <p>{props.commentaires}</p>
      </CardBody>
      <CardFooter className="text-center">
        <Button
          size="sm"
          color="danger"
          className="animation-on-hover"
          onClick={() => props.supp(props.id)}
        >
          Supprimer
        </Button>
      </CardFooter>
    </Card>
  );
};

export default VehiculeGuest;
