import React from "react";
import { Route, Switch } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Feed from "../pages/Feed/feedVA";
import FeedVR from "../pages/Feed/feedVR";
import FeedPL from "../pages/Feed/feedPL";
import Profil from "../pages/Profil/Profil";
import Task from "../pages/Task/Task";
import Archives from "../pages/Archives/Archives";
import RDVerif from "../pages/RDVerif/RDVerif";

const Admin = (props) => {
  return (
    <Switch>
      <Sidebar>
        <Route exact path="/admin/va" render={() => <Feed />} />
        <Route exact path="/admin/vr" render={() => <FeedVR />} />
        <Route exact path="/admin/pl" render={() => <FeedPL />} />
        <Route exact path="/admin/settings" render={() => <Profil />} />
        <Route exact path="/admin/task" render={() => <Task />} />
        <Route exact path="/admin/archives" render={() => <Archives />} />
        <Route exact path="/admin/verifier" render={() => <RDVerif />} />
      </Sidebar>
    </Switch>
  );
};

export default Admin;
