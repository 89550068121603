import React, { Component } from "react";
import VehiculeGuest from "../../components/VA/VehiculeGuest";
import "./Feed.css";

class FeedGuestPL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      va: [],
      user: {},
      isEditing: false,
      EditVa: null,
      modal: false,
      refresh: null,
    };
  }


  componentDidMount() {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/plg/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.pl.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
      let refresh_interval = setInterval(this.refresh, 300000);
      this.setState({ refresh: refresh_interval });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/plg/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch va status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            va: resData.pl.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }
  deleteHandler = (id) => {
    console.log("you can't delete this vehicule")
  };
  render() {
    return (
      <div className="feed ml-2">
        {this.state.va.map((voiture) => (
          <VehiculeGuest
            voit={((voiture.invite_id) ? voiture.firstname + " " + voiture.lastname : "Admin")}
            key={voiture._id}
            societe={voiture.societe}
            phone={voiture.phone}
            modele={voiture.modele}
            immatriculation={voiture.immatriculation}
            id={voiture._id}
            arrival={voiture.arrival}
            deliver={voiture.deliver}
            en_cours={voiture.en_cours}
            commentaires={voiture.commentaires}
            supp={this.deleteHandler}
            pl={1}
          />
        ))}
      </div>
    );
  }
}

export default FeedGuestPL;
