import React from "react";
import { Route, Switch } from "react-router-dom";
import Profil from "../pages/Profil/Profil";
import SidebarUser from "../components/Sidebar/SidebarUser";
import Paiement from "../pages/Payements/Paiement";

const Guest = (props) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/utilisateurs/paiement"
          render={() => <Paiement />}
        />
        <SidebarUser>
          <Route exact path="/utilisateurs/profil" render={() => <Profil />} />
        </SidebarUser>
      </Switch>
    </div>
  );
};

export default Guest;
