import React from "react";
import { Route, Switch } from "react-router-dom";
import Profil from "../pages/Profil/Profil";
import FeedGuest from "../pages/Feed/feedGuest";
import SidebarGuest from "../components/Sidebar/SidebarGuest";
import FeedGuestPL from "../pages/Feed/feedGuestPL";

const Guest = (props) => {
  return (
    <SidebarGuest>
      <Switch>
        <Route exact path="/guest/profil" render={() => <Profil />} />
        <Route exact path="/guest/rdv" render={() => <FeedGuest />} />
        <Route exact path="/guest/livraison" render={() => <FeedGuestPL />} />
      </Switch>
    </SidebarGuest>
  );
};

export default Guest;
