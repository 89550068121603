import React from "react";
import { Component } from "react";
import { useHistory } from "react-router-dom";

import PayPalBtn from "../../components/PayPal/PayPalBtn";


const Paiement  = (props) => {
    let history = useHistory()

    const paypalSubscribe = (data, actions) => {
      return actions.subscription.create({
        plan_id: "P-9JD63337EA119281PL7MKASA",
      });
    };
    
    const paypalOnError = (err) => {
      console.log("Error");
    };
    
    const paypalOnApprove = (data, detail) => {
        
        fetch(
            "https://asicar.herokuapp.com/auth/paiement/" +
              localStorage.getItem("userId"),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paiement_id: data.subscriptionID,
              }),
            }
          )
            .then((res) => {
              if (res.status !== 201) {
                throw new Error("Failed to fetch user status.");
              }
              else
              {
                history.push('/admin/task')
              }
              return res.json();
            }).catch((err) => console.log(err));
      console.log("Paypal approved");
      console.log(data.subscriptionID);
    };
    return (
      <div>
        <p>Image logiciel</p>
        <p>Abonnement</p>
        <PayPalBtn
          amount="50"
          currency="EUR"
          createSubscription={paypalSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
        />
      </div>
    );
}

export default Paiement;
