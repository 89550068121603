import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";

import { createBrowserHistory } from "history";
const hist = createBrowserHistory();
const app = (
  <HashRouter history={hist}>
    <App />
  </HashRouter>
);
ReactDOM.render(app, document.getElementById("root"));
