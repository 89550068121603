import React from "react";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// Create Document Component
const MyDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>
          {props.ar.firstname} • {props.ar.lastname}
        </Text>
        <Text>
          {props.ar.societe} • {props.ar.phone}
        </Text>
        <Text>
          {props.ar.modele} • {props.ar.immatriculation}
        </Text>
        <Text>
          {(new Date(props.ar.arrival)).toLocaleDateString('fr-FR', options)} • {(new Date(props.ar.deliver)).toLocaleDateString('fr-FR', options)}
        </Text>
        <Text>{props.ar.commentaires} </Text>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
