import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import "./Task.css";
import ReactTable from "react-table-v6";
const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
class Task extends Component {
  constructor(props) {
    super(props);
    let d = new Date();
    let day = d.getDate();
    if (day < 9) {
      day = "-0" + d.getDate();
    }
    let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + day;
    this.state = {
      va: [],
      vr: [],
      pl: [],
      today_date: date,
      refresh: null,
      modal: false,
    };
  }

  toogleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  componentDidMount() {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vabydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.va.map((voiture) => {
            return {
              ...{
                firstname: voiture.firstname,
                lastname: voiture.lastname,
                societe: voiture.societe,
                phone: voiture.phone,
                modele: voiture.modele,
                immatriculation: voiture.immatriculation,
                arrival: new Date(voiture.arrival).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                deliver: new Date(voiture.deliver).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                commentaires: voiture.commentaires,
              },
            };
          }),
        });
      })
      .catch((err) => console.log(err));
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vrbydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch vr status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          vr: resData.vr.map((vr) => {
            return {
              ...{
                firstname: vr.firstname,
                lastname: vr.lastname,
                societe: vr.societe,
                phone: vr.phone,
                modele: vr.modele,
                immatriculation: vr.immatriculation,
                arrival: new Date(vr.arrival).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                deliver: new Date(vr.deliver).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                commentaires: vr.commentaires,
                actions: (
                  <div className="actions-right">
                    <Button onClick={this.toogleModal}>
                      <Modal
                        isOpen={this.state.modal}
                        toggle={this.toogleModal}
                        modalClassName="modal-black"
                      >
                        <ModalBody>
                          <h2 className="title">
                            {vr.firstname} {vr.lastname}
                          </h2>
                          <h4>
                            <strong className="accent">
                              {vr.modele} - {vr.immatriculation}
                            </strong>
                          </h4>
                          <p>
                            {vr.societe} -{" "}
                            {vr.phone ? "0" + vr.phone.toString() : null}
                          </p>
                          <p>
                            Arriver:{" "}
                            {new Date(vr.arrival).toLocaleDateString(
                              "fr-FR",
                              options
                            )}
                          </p>
                          <p>
                            Livraison:{" "}
                            {new Date(vr.deliver).toLocaleDateString(
                              "fr-FR",
                              options
                            )}
                          </p>
                          <p>Compagnon: {vr.member}</p>
                          <p>{vr.commentaires}</p>
                        </ModalBody>
                      </Modal>
                      Détails
                    </Button>
                  </div>
                ),
              },
            };
          }),
        });
      })
      .catch((err) => console.log(err));
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/plbydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch pl status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          pl: resData.pl.map((voiture) => {
            return {
              ...{
                firstname: voiture.firstname,
                lastname: voiture.lastname,
                societe: voiture.societe,
                phone: voiture.phone,
                modele: voiture.modele,
                immatriculation: voiture.immatriculation,
                arrival: new Date(voiture.arrival).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                deliver: new Date(voiture.deliver).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                commentaires: voiture.commentaires,
              },
            };
          }),
        });
      })
      .catch((err) => console.log(err));

    let refresh_interval = setInterval(this.refresh, 300000);
    this.setState({ refresh: refresh_interval });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.today_date !== this.state.today_date) {
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/vabydate/" +
          this.state.today_date,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch va status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            va: resData.va.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/vrbydate/" +
          this.state.today_date,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch vr status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            vr: resData.vr.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/plbydate/" +
          this.state.today_date,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch pl status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            pl: resData.pl.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }
  refresh = () => {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vabydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.va.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vrbydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch vr status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          vr: resData.vr.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/plbydate/" +
        this.state.today_date,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch pl status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          pl: resData.pl.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return (
      <div>
        <div className="task mt-3">
          <Card style={{ width: "50rem" }}>
            <CardBody>
              <FormGroup>
                <h5>Choisissez votre date</h5>
                <Input
                  type="date"
                  id="arrival"
                  date-date-fromat="d MMM yyyy"
                  min="2019-01-01"
                  max="2030-12-31"
                  value={this.state.today_date}
                  onChange={(e) => {
                    this.setState({ today_date: e.target.value });
                  }}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </div>
        <div className="content mx-4">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Voiture attendue</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.va}
                    filterable
                    columns={[
                      {
                        Header: "Prénom",
                        accessor: "firstname",
                      },
                      {
                        Header: "Nom",
                        accessor: "lastname",
                      },
                      {
                        Header: "Société",
                        accessor: "societe",
                      },
                      {
                        Header: "Modèle",
                        accessor: "modele",
                      },
                      {
                        Header: "Immatriculation",
                        accessor: "immatriculation",
                      },
                      {
                        Header: "Date d'arrivée",
                        accessor: "arrival",
                      },
                      {
                        Header: "Date de livraison",
                        accessor: "deliver",
                      },
                      {
                        Header: "Commentaires",
                        accessor: "commentaires",
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    previousText="Précédent"
                    nextText="Suivant"
                    loadingText="Chargement..."
                    noDataText="Pas de données trouvées"
                    pageText="Page"
                    ofText="sur"
                    rowsText="lignes"
                    // Accessibility Labels
                    pageJumpText="Sauter la page"
                    rowsSelectorText="Lignes par page"
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Voiture en retard</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.vr}
                    filterable
                    columns={[
                      {
                        Header: "Prénom",
                        accessor: "firstname",
                      },
                      {
                        Header: "Nom",
                        accessor: "lastname",
                      },
                      {
                        Header: "Société",
                        accessor: "societe",
                      },
                      {
                        Header: "Modèle",
                        accessor: "modele",
                      },
                      {
                        Header: "Immatriculation",
                        accessor: "immatriculation",
                      },
                      {
                        Header: "Date d'arrivée",
                        accessor: "arrival",
                      },
                      {
                        Header: "Date de livraison",
                        accessor: "deliver",
                      },
                      {
                        Header: "Commentaires",
                        accessor: "commentaires",
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    previousText="Précédent"
                    nextText="Suivant"
                    loadingText="Chargement..."
                    noDataText="Pas de données trouvées"
                    pageText="Page"
                    ofText="sur"
                    rowsText="lignes"
                    // Accessibility Labels
                    pageJumpText="Sauter la page"
                    rowsSelectorText="Lignes par page"
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Prêt à la livraison</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.pl}
                    filterable
                    columns={[
                      {
                        Header: "Prénom",
                        accessor: "firstname",
                      },
                      {
                        Header: "Nom",
                        accessor: "lastname",
                      },
                      {
                        Header: "Société",
                        accessor: "societe",
                      },
                      {
                        Header: "Modèle",
                        accessor: "modele",
                      },
                      {
                        Header: "Immatriculation",
                        accessor: "immatriculation",
                      },
                      {
                        Header: "Date d'arrivée",
                        accessor: "arrival",
                      },
                      {
                        Header: "Date de livraison",
                        accessor: "deliver",
                      },
                      {
                        Header: "Commentaires",
                        accessor: "commentaires",
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    previousText="Précédent"
                    nextText="Suivant"
                    loadingText="Chargement..."
                    noDataText="Pas de données trouvées"
                    pageText="Page"
                    ofText="sur"
                    rowsText="lignes"
                    // Accessibility Labels
                    pageJumpText="Sauter la page"
                    rowsSelectorText="Lignes par page"
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Task;
