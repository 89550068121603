import React, { useState } from "react";
import { Button, Card, CardBody, Form, Input } from "reactstrap";

const ChangeMail = (props) => {
  const [newMail, setNewMail] = useState("");

  return (
    <Card style={{ width: "20rem" }} className="mr-3">
      <CardBody>
        <h2>Changer votre email</h2>

        <Input
          id="new"
          value={newMail}
          required
          placeholder="Nouveau mail"
          onChange={(e) => setNewMail(e.target.value)}
        />
        <br />
        <div className="text-center">
          <Button
            color="info"
            className="animation-on-hover"
            onClick={(e) => props.onAdd(e, [newMail])}
          >
            Mettre à jour
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChangeMail;
