import React, { Component } from "react";
import EditVehicule from "../../components/Form/EditVehicule/EditVehicule";
import VehiculeForm from "../../components/Form/Vehicule/VehiculeForm";
import VA from "../../components/VA/VA";
import "./Feed.css";
import { Button, Modal, ModalBody } from "reactstrap";

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      va: [],
      user: {},
      isEditing: false,
      EditVa: null,
      update: 0,
      modal: false,
      refresh: null,
    };
  }

  componentDidMount() {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/va/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.va.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vr/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch vr status.");
        }
        return res.json();
      })
      .then((resData) => {
        resData.vr.map((voiture) => {
          if (Date.parse(voiture.deliver) > Date.now()) {
            this.addVa2(voiture);
            this.dh(voiture._id);
          }
          return voiture;
        });
      })
      .catch((err) => console.log(err));
    let refresh_interval = setInterval(this.refresh, 300000);
    this.setState({ refresh: refresh_interval });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/va/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch va status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            va: resData.va.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }

  sortVA = () => {
    const sorting = this.state.va.sort(function (a, b) {
      return new Date(a.arrival) - new Date(b.arrival);
    });
    this.setState({ va: sorting });
  };

  sortVADeliver = () => {
    const sorting = this.state.va.sort(function (a, b) {
      return new Date(a.deliver) - new Date(b.deliver);
    });
    this.setState({ va: sorting });
  };

  refresh = () => {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/va/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.va.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
  };
  dh = (id) => {
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/vr/" +
      id;
    fetch(lien, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to delete vr.");
        }
        this.setState({ update: !this.state.update });
        return res.json();
      })
      .catch((err) => console.log(err));
  };
  addVa2 = (data) => {
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/va/";
    fetch(lien, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lastname: data.lastname,
        firstname: data.firstname,
        societe: data.societe,
        phone: data.phone,
        modele: data.modele,
        immatriculation: data.immatriculation,
        arrival: data.arrival,
        deliver: data.deliver,
        commentaires: data.commentaires,
        member: data.member,
        en_cours: 0,
        verif: 1,
        user_id: localStorage.getItem("userId"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  work = (id, data) => {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/va/" +
        id,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lastname: data.lastname,
          firstname: data.firstname,
          societe: data.societe,
          phone: data.phone,
          modele: data.modele,
          immatriculation: data.immatriculation,
          arrival: data.arrival,
          deliver: data.deliver,
          commentaires: data.commentaires,
          UserID: localStorage.getItem("userId"),
          member: data.member,
          en_cours: !data.en_cours & 1,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteHandler = (id) => {
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/va/" +
      id;
    fetch(lien, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to delete va.");
        }
        this.setState({ update: !this.state.update });
        return res.json();
      })
      .catch((err) => console.log(err));
  };

  editHandler = (data) => {
    console.log(Date.parse(data.deliver) < Date.now());
    this.setState({ isEditing: true, EditVa: data, modal: !this.state.modal });
  };

  addVa = (e, data) => {
    e.preventDefault();
    let v_util = "va/";
    if (Date.parse(data[7]) < Date.now()) {
      v_util = "vr/";
    }
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/" +
      v_util;
    fetch(lien, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lastname: data[0],
        firstname: data[1],
        societe: data[2],
        phone: data[3],
        modele: data[4],
        immatriculation: data[5],
        arrival: data[6],
        deliver: data[7],
        commentaires: data[8],
        member: data[9],
        en_cours: 0,
        verif: 1,
        user_id: localStorage.getItem("userId"),
        invite_id: null,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update, modal: !this.state.modal });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editVa = (e, data) => {
    e.preventDefault();
    console.log(data);

    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/va/" +
      data[10];
    fetch(lien, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lastname: data[0],
        firstname: data[1],
        societe: data[2],
        phone: data[3],
        modele: data[4],
        immatriculation: data[5],
        arrival: data[6],
        deliver: data[7],
        commentaires: data[8],
        en_cours: data[9],
        member: data[11],
        verif: 1,
      }),
    })
      .then((res) => {
        this.setState({ isEditing: false });
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update, modal: !this.state.modal });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deliv = (id, data) => {
    console.log(data);
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/pl/";
    fetch(lien, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lastname: data.lastname,
        firstname: data.firstname,
        societe: data.societe,
        phone: data.phone,
        modele: data.modele,
        immatriculation: data.immatriculation,
        arrival: data.arrival,
        deliver: data.deliver,
        commentaires: data.commentaires,
        member: data.member,
        invite_id: data.invite_id,
        en_cours: 1,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update });
        this.deleteHandler(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toogleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  cancelHandler = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    let form = (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toogleModal}
        modalClassName="modal-black"
      >
        <ModalBody>
          <VehiculeForm onAdd={this.addVa} onCancel={this.cancelHandler} />
        </ModalBody>
      </Modal>
    );
    if (this.state.isEditing && this.state.modal) {
      form = (
        <Modal
          isOpen={this.state.modal}
          toggle={this.toogleModal}
          modalClassName="modal-black"
        >
          <ModalBody>
            <EditVehicule
              onEdit={this.editVa}
              onCancel={this.cancelHandler}
              key={this.state.EditVa.id}
              firstname={this.state.EditVa.firstname}
              lastname={this.state.EditVa.lastname}
              societe={this.state.EditVa.societe}
              phone={this.state.EditVa.phone}
              modele={this.state.EditVa.modele}
              member={this.state.EditVa.member}
              immatriculation={this.state.EditVa.immatriculation}
              id={this.state.EditVa.id}
              arrival={this.state.EditVa.arrival}
              deliver={this.state.EditVa.deliver}
              commentaires={this.state.EditVa.commentaires}
              en_cours={this.state.EditVa.en_cours}
            />
          </ModalBody>
        </Modal>
      );
    }
    return (
      <div>
        <div className="feed ml-2">
          {this.state.va.map((voiture) => (
            <VA
              key={voiture._id}
              voit={
                voiture.invite_id
                  ? voiture.firstname + " " + voiture.lastname
                  : "Admin"
              }
              firstname={voiture.firstname}
              lastname={voiture.lastname}
              societe={voiture.societe}
              phone={voiture.phone}
              modele={voiture.modele}
              immatriculation={voiture.immatriculation}
              id={voiture._id}
              arrival={voiture.arrival}
              deliver={voiture.deliver}
              member={voiture.member}
              invite_id={voiture.invite_id}
              commentaires={voiture.commentaires}
              en_cours={voiture.en_cours}
              supp={this.deleteHandler}
              enc={this.work}
              edit={this.editHandler}
              deliv_clik={this.deliv}
              pl={0}
            />
          ))}
          {form}
        </div>
        <div className="add_b">
          <Button
            color="success"
            className="btn-icon btn-round"
            onClick={this.toogleModal}
          >
            <i className="fa fa-plus my-float"></i>
          </Button>
          <Button
            color="default"
            className="btn-icon btn-round"
            onClick={this.sortVA}
          >
            DA
          </Button>
          <Button
            color="default"
            className="btn-icon btn-round"
            onClick={this.sortVADeliver}
          >
            DL
          </Button>
        </div>
      </div>
    );
  }
}

export default Feed;
