import React, { useState } from "react";
import { Button, Card, CardBody, Form, Input } from "reactstrap";

const ChangePswd = (props) => {
  const [actual, setActual] = useState(""); //useState("props.lastname")
  const [newPswd, setNewPswd] = useState("");
  return (
    <Card style={{ width: "20rem" }} className="mr-3">
      <CardBody>
        <h2>Changer mot de passe</h2>
        <Input
          id="actual_mdp"
          value={actual}
          required
          placeholder="Votre mot de passe actuel"
          onChange={(e) => setActual(e.target.value)}
        />
        <br />
        <Input
          id="new_mdp"
          value={newPswd}
          required
          placeholder="Nouveau mot de passe"
          onChange={(e) => setNewPswd(e.target.value)}
        />
        <br />
        <div className="text-center">
          <Button
            color="info"
            className="animation-on-hover"
            onClick={(e) => props.onAdd(e, [actual, newPswd])}
          >
            Mettre à jour
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChangePswd;
