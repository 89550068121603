import React from "react";
import { Button } from "reactstrap";
const DeleteInvite = (props) => {
  return (
    <div>
      <Button className="btn-icon" color="danger" size="sm" onClick={() => props.onDelete(props.id)}>X</Button>
    </div>
  );
};

export default DeleteInvite;
