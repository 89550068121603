import React, { useState } from "react";
import { Button, Card, CardBody, Form, Input } from "reactstrap";

const AddInvite = (props) => {
  const [mail, setMail] = useState(""); //useState("props.lastname")
  const [pswd, setPswd] = useState("");

  return (
    <Card style={{ width: "20rem" }} className="mr-3">
      <CardBody>
        <h2>Ajouter des invités</h2>
        <Form onSubmit={(e) => props.onAdd(e, [mail, pswd])}>
          <Input
            required
            id="mail"
            value={mail}
            placeholder="Adresse e-mail"
            onChange={(e) => setMail(e.target.value)}
          />
          <br />
          <Input
            required
            id="pswd"
            value={pswd}
            placeholder="Mot de passe"
            onChange={(e) => setPswd(e.target.value)}
          />
          <br />
          <div className="text-center">
            <Button color="info" className="animation-on-hover" type="submit">
              Ajouter
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default AddInvite;
