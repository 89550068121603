import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import Logo from "../svg/LogoSVG.svg";
import LogoAs from "../svg/Logo transparent.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  FormGroup,
} from "reactstrap";
import "./Auth.css";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let history = useHistory();
  return (
    <>
      <img src={LogoAs} width="20%" className="mx-auto d-block" alt="logo" />
      <div className="auth mt-5">
        <Card style={{ width: "50rem" }}>
          <form onSubmit={(e) => props.onLogin(e, [email, password])}>
            <CardHeader>
              <h2>Connectez-vous</h2>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Input
                  placeholder="Email..."
                  autoComplete="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="card-input mb-1"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  className="card-input"
                  placeholder="Mot de passe..."
                  autoComplete="new-password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </CardBody>
            <CardFooter className="card-footer-login text-center">
              <Button color="info" className="btn btn-login" type="submit">
                Me connecter
              </Button>
            </CardFooter>
            <CardFooter>
              <Button
                color="black"
                onClick={(e) => history.push("/inscription")}
              >
                S'inscrire
              </Button>
              <Button
                onClick={() => {
                  setEmail("");
                  setPassword("");
                }}
                color="danger"
                type="reset"
                className="float-right"
              >
                Annuler
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
};

export default Login;
