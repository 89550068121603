import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./VehiculeForm.css";
const VehiculeForm = (props) => {
  const [lastname, setLastname] = useState(""); //useState("props.lastname")
  const [firstname, setFirstname] = useState("");
  const [societe, setSociete] = useState("Particulier");
  const [phone, setPhone] = useState("");
  const [modele, setModele] = useState("");
  const [immatriculation, setImmatriculation] = useState("");
  const [arrival, setArrival] = useState("");
  const [deliver, setDeliver] = useState("");
  const [commentaires, setCommentaires] = useState("");
  const [compagnon, setCompagnon] = useState("");

  return (
    <Form
      onSubmit={(e) =>
        props.onAdd(e, [
          lastname,
          firstname,
          societe,
          phone,
          modele,
          immatriculation,
          arrival,
          deliver,
          commentaires,
          compagnon,
        ])
      }
    >
      <div className="form-row">
        <FormGroup className="col-md-6">
          <Label for="inputEmail4">Nom de famille</Label>
          <Input
            required
            id="lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            placeholder="Nom de famille..."
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <Label for="inputPassword4">Prénom</Label>
          <Input
            required
            id="firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            placeholder="Prénom..."
          />
        </FormGroup>
      </div>

      <div className="form-row">
        <FormGroup className="col-md-6">
          <Label for="inputEmail4">Société</Label>
          <Input
            id="societe"
            placeholder="Société..."
            value={societe}
            onChange={(e) => setSociete(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <Label for="inputPassword4">Téléphone</Label>
          <Input
            id="phone"
            placeholder="Téléphone..."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormGroup>
      </div>

      <div className="form-row">
        <FormGroup className="col-md-6">
          <Label for="inputEmail4">Modèle</Label>
          <Input
            required
            id="modele"
            placeholder="Modèle..."
            value={modele}
            onChange={(e) => setModele(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <Label for="inputPassword4">Immatriculation</Label>
          <Input
            required
            id="immatriculation"
            placeholder="Immatriculation..."
            value={immatriculation}
            onChange={(e) => setImmatriculation(e.target.value)}
          />
        </FormGroup>
      </div>

      <div className="form-row">
        <FormGroup className="col-md-6">
          <Label for="inputEmail4">Date d'arrivée</Label>
          <Input
            required
            type="date"
            id="arrival"
            date-date-fromat="d MMM yyyy"
            min="2019-01-01"
            max="2030-12-31"
            value={arrival}
            onChange={(e) => setArrival(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="col-md-6">
          <Label for="inputPassword4">Date de livraison</Label>

          <Input
            type="date"
            id="deliver"
            date-date-fromat="d MMM yyyy"
            min="2019-01-01"
            max="2030-12-31"
            value={deliver}
            onChange={(e) => setDeliver(e.target.value)}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label>Compagnon</Label>
        <Input
          id="compagnon"
          type="text"
          placeholder="Compagnon..."
          value={compagnon}
          onChange={(e) => setCompagnon(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="inputPassword4">Commentaires</Label>
        <Input
          id="commentaires"
          type="textarea"
          placeholder="Commentaires..."
          value={commentaires}
          onChange={(e) => setCommentaires(e.target.value)}
        />
      </FormGroup>

      <Button color="info" type="submit">
        Ajouter
      </Button>
      <Button
        type="reset"
        color="danger"
        onClick={() => props.onCancel()}
        className="float-right"
      >
        Annuler
      </Button>
    </Form>
  );
};

export default VehiculeForm;
