import React from "react";
import "./VA.css";
import { Badge, Button, Card, CardBody, CardFooter } from "reactstrap";

const VA = (props) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let valider = null;
  let supprimer = null;
  let edit = null;
  let deliver = null;
  let ec = null;
let archiver = null;
  if (props.valid) {
    valider = (
      <Button
        size="sm"
        color="danger"
        className="animation-on-hover"
        onClick={() => props.valid(props.id, props)}
      >
        Valider
      </Button>
    );
  }
  if (props.deliv_clik) {
    deliver = (
      <Button
        size="sm"
        color="success"
        className="animation-on-hover"
        onClick={() => props.deliv_clik(props.id, props)}
      >
        Livraison
      </Button>
    );
  }
  if (props.archiver) {
    archiver = (
      <Button
        size="sm"
        color="success"
        className="animation-on-hover"
        onClick={() => props.archiver(props.id, props)}
      >
        Archiver
      </Button>
    );
  }
  if (props.edit) {
    edit = (
      <Button
        size="sm"
        color="info"
        className="animation-on-hover"
        onClick={() => props.edit(props)}
      >
        Modifier
      </Button>
    );
  }
  if (props.supp) {
    supprimer = (
      <Button
        size="sm"
        color="danger"
        className="animation-on-hover"
        onClick={() => props.supp(props.id)}
      >
        Supprimer
      </Button>
    );
  }
  if (props.enc) {
    ec = (
      <Button
        size="sm"
        color="warning"
        className="animation-on-hover"
        onClick={() => props.enc(props.id, props)}
      >
        En cours
      </Button>
    );
  }

  return (
    <Card style={{ width: "20rem" }} className="mr-2 mt-2">
      <div className="mt-1 ml-1">
        <Badge color={props.en_cours ? "success" : "danger"}>En cours</Badge>
        <Badge color={props.pl ? "success" : "danger"}>Livraison</Badge>
        <Badge color="primary">Ajouté par {props.voit}</Badge>
      </div>
      <CardBody>
        <h2 className="title">
          {props.firstname} {props.lastname}
        </h2>
        <h4>
          <strong className="accent">
            {props.modele} - {props.immatriculation}
          </strong>
        </h4>
        <p>
          {props.societe} - {(props.phone) ? "0" + props.phone.toString(): null}
        </p>
        <p>
          Arriver:{" "}
          {new Date(props.arrival).toLocaleDateString("fr-FR", options)}
        </p>
        <p>
          Livraison:{" "}
          {new Date(props.deliver).toLocaleDateString("fr-FR", options)}
        </p>
        <p>Compagnon: {props.member}</p>
        <p>{props.commentaires}</p>
      </CardBody>
      <CardFooter className="text-center">
        {archiver}
        {deliver}
        {ec}
        {edit}
        {supprimer}
        {valider}
      </CardFooter>
    </Card>
  );
};

export default VA;
