import React, { useState } from "react";
import { Card, Button, Input, Form, CardBody } from "reactstrap";

const ChangeInfos = (props) => {
  const [firstname, SetFirstname] = useState("");
  const [lastname, SetLastname] = useState("");
  const [societe, SetSociete] = useState("");
  const [phone, SetPhone] = useState("");
  return (
    <Card style={{ width: "20rem" }} className="mr-3">
      <CardBody>
        <h1>Informations utilisateurs</h1>

        <Input
          className="mb-1"
          placeholder="Prénom"
          value={firstname}
          onChange={(e) => SetFirstname(e.target.value)}
          required
        />
        <Input
          className="mb-1"
          placeholder="Nom de famille"
          value={lastname}
          onChange={(e) => SetLastname(e.target.value)}
          required
        />
        <Input
          className="mb-1"
          placeholder="Société"
          value={societe}
          onChange={(e) => SetSociete(e.target.value)}
          required
        />
        <Input
          className="mb-1"
          placeholder="Téléphone"
          value={phone}
          onChange={(e) => SetPhone(e.target.value)}
          required
        />
        <div className="text-center">
          <Button
            color="info"
            className="animation-on-hover"
            type="submit"
            onClick={(e) => {
              props.onUpdate(e, [firstname, lastname, societe, phone]);
            }}
          >
            Mettre à jour
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChangeInfos;
