import React, { Component } from "react";
import { Table } from "reactstrap";


class SuperAdmin extends Component {
  state = {
    user: [],
  };
  componentDidMount() {
    fetch(
      "https://asicar.herokuapp.com/auth/all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({
          user: resData.user.map((user) => {
            return { ...user };
          }),
        });
      })
      .catch((err) => console.log(err));
  }
  render() {
    
    return (
      <div className="task">
          <h1>Utilisateurs</h1>
        <Table className="table">
          <thead>
            <tr>
              <th scope="col">Prénom</th>
              <th scope="col">Nom</th>
              <th schope="col">Role</th>
              <th schope="col">Edit</th>
              <th schope="col">Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {this.state.user.map((user) => (
              <tr key={user._id}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td><button>Change Role</button></td>
                <td><button>EDIT</button></td>
                <td><button>Supprimer</button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SuperAdmin;
